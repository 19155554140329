body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1 {
      text-align: center;
  }
  
  hr {
      margin: 32px 0;
  }
  
  .buttonClass, .inputClass {
      border-radius: 8px;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      margin: 8px 0;
      padding: 8px;
      width: 100%;
  }
  
  .inputClass {
      border: 1px solid black;
  }
  
  .buttonClass {
      border: 1px solid black;
      cursor: pointer;
  }
  
  
  .content-container {
      background-color: white;
      border-radius: 8px;
      min-width: 300px;
      max-width: 800px;
      padding: 32px;
  }
  
  .success {
      background-color: #90ee90;
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 16px;
      text-align: center;
  }
  
  .fail {
      background-color: #ffcccb;
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 16px;
      text-align: center;
  }