


body {
    
    background-color: #ffdee9;
    
    font-family: 'Karla', sans-serif;
}

.PanelsBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
  }
  
.CodeBox {
  display:flex;
  max-height: 1000px;
  max-width: 100%;
}

.ControlsBox {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
}
  
.main {
    background-color: #F5F5F5;
    height: 600px;
    max-width: 800px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.title {   
    margin: 0;
}

.instructions {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    margin-top: 0;
    text-align: center;
    max-width: 75%;
}

.brick-container {
    display: grid;
    
    gap: 5px;
    margin-bottom: 40px;
}

.brick-face {
    height: 50px;
    width: 50px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.brick-face-empty {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brick-num {
    font-size: 2rem;
}

.ShowCodeButton {
    max-width: 150px;
    padding: 5px 15px 5px 15px;
    border: none;
    border-radius: 6px;
    background-color: #096461;
    color: white;
    font-size: 1.2rem;
    font-family: 'Karla', sans-serif;
    cursor: pointer;
}

.shuffle-bricks {
    max-width: 150px;
    padding: 5px 15px 5px 15px;
    border: none;
    border-radius: 6px;
    background-color: #096461;
    color: white;
    font-size: 1.8rem;
    font-family: 'Karla', sans-serif;
    cursor: pointer;
}

.shuffle-bricks:focus {
    outline: none;
}

.shuffle-bricks:active {
    box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}

/* Repeated styles 4 times for each direction can possibly be written more compactly, but works and revisit if going to use more*/
/* Tried react-jss but was more cumbersome to use, most docs for class implementation, revisit if going to use more. */

.exampleN-enter {
    transform: translate(0,100%);
 }

 .exampleS-enter {
    transform: translate(0,-100%);
 }

 .exampleE-enter {
    transform: translate(-100%, 0);
 }

 .exampleW-enter {
    transform: translate(100%, 0);
 }


 .exampleN-enter-active {
    transform: translate(0);
    transition: transform 300ms linear;
 }

 .exampleS-enter-active {
    transform: translate(0);
    transition: transform 300ms linear;
 }

 .exampleE-enter-active {
    transform: translate(0);
    transition: transform 300ms linear;
 }

 .exampleW-enter-active {
    transform: translate(0);
    transition: transform 300ms linear;
 }

 


