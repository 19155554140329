body {
    font-family: "Roboto", sans-serif;
    margin: 0px;
}

h1 {
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 10px;
    text-align: center;
  }

flutter-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.2rem;
  }

  .instructions-flutter {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.2em;
    margin-top: 2rem;
    text-align: justify;
    max-width: 80%;
    line-height: 1.5;
  }
  
  iframe {
    
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0px 0px 10px #BBBBBB;
  }
  
  
  
  
.instructions-flutterold {
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    font-size: 0.9em;
    margin-top: 0;
    text-align: left;
    max-width: 70%;
}
